<template>
  <div class="container forget-password-page">
<!--    <nav aria-label="breadcrumb">-->
<!--      <ol class="breadcrumb pl-0 mb-0 pb-2">-->
<!--        <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>-->
<!--        <li class="breadcrumb-item active" aria-current="page">{{ $t('reset-password.title') }}</li>-->
<!--      </ol>-->
<!--    </nav>-->
    <nav class="breadcrumbs-block">
      <ul class="breadcrumbs-list">
        <li class="breadcrumbs-item"><a href="/">Главная</a></li>
        <li class="breadcrumbs-item">{{ $t('reset-password.title') }}</li>
      </ul>
    </nav>

    <div class="row signin-wrapper">
      <div class="col-lg-6 col-md-12 signin-col-logo">
        <div class="signin-logo">
          <img src="/images/qabilet-blue-logo.svg" width="236" height="114" style="max-width: 100%;">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 signin-col-form">
        <div class="forget-password">
          <div class="">
            <div class="forget-password__title">
              {{ $t('reset-password.title') }}
            </div>
            <div class="forget-password__logo">
              <img src="/images/qabilet-blue-logo.svg">
            </div>
            <div v-if="EmailSended" class="description">
              <h1>{{ $t('reset-password.email-sended') }}</h1>
            </div>
            <div v-else class="description">
              <form-group :field="$v.email" :label="$t('reset-password.email-label')" name="email" class="mt-4" :class="{'text-danger': !$v.email.email || $v.email.$dirty&&!$v.email.required}">
                <el-input
                    id="email"
                    :placeholder="$t('reset-password.email-placeholder')"
                    v-model="email"
                    @change="$v.email.$touch()"
                    @blur="onGetIin"
                    clearable>
                </el-input>
                <p v-if="!$v.email.email" class="help-block text-danger">{{ $t('form-group.email-required') }}</p>
                <p v-if="$v.email.$dirty&&!$v.email.required" class="help-block text-danger">{{ $t('form-group.required') }}</p>
              </form-group>

              <form-group :field="$v.iin" :label="$t('signin-form.member-label')" name="iin">
                <el-select v-model="iin" :placeholder="$t('signin-form.member-placeholder')" :loading="iinLoading" class="w-100">
                  <el-option
                      v-for="iin in iinArray"
                      @change="$v.iin.$touch()"
                      :key="iin.value"
                      :label="iin.label"
                      :value="iin.value">
                  </el-option>
                </el-select>
              </form-group>

              <div class="form-group pt-5">
                <el-button type="primary" @click="sendConfirmation">{{ $t('reset-password.next-btn') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
import {mapActions} from "vuex";
import {minLength, minValue, required, email} from "vuelidate/lib/validators";
export default {
  name: 'Login',
  components: {
    LoginForm
  },
  methods: {
    sendConfirmation(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$http.get(window.API_ROOT + '/api/password/reset/email?iin=' + this.iin)
        .then((res) => {
          this.EmailSended = true;
        }).catch((e) => {
        })
    },
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetIin() {
      this.iinLoading = true;
      if (!this.$v.email.$invalid){
        if (this.email != '') {
          this.getIinByEmail({
            email: this.email
          }).then((res) => {
            this.iinLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.iinLoading = false;
          })
        }
      }
    },
    ...mapActions({
      'getIinByEmail': 'getIinByEmail',
    })
  },
  data(){
    return {
      email: '',
      iin: '',
      iinArray: [],
      iinLoading: false,
      EmailSended: false
    }
  },
  validations: {
    email: { email, required },
    iin: { required }
  }
}
</script>

<style>
.signin-logo {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    height: 377px;
    width: 443px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.signin-wrapper {
  padding-bottom: 85px;
  padding-top: 35px;
}
.signin-col-logo {
  margin-left: 0;
  margin-right: 33px;
}
.signin-col-form {
  max-width: 414px;
  margin-right: 0;
  margin-left: 33px;
}
.text-danger span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('/images/close.svg');
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #dc3545;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.text-success span{
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("/images/ok.svg");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  background-color: #018837;
  float: left;
  border-radius: 24px;
  margin-right: 10px;
}
.el-alert .el-alert__title{
  text-transform: capitalize;
}
.forget-password {
  background: #FFFFFF;
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 30px;
  position: relative;
  height: 377px;
  width: 384px;
}
.forget-password__title {
  color: #2C2C2C;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 7px;
  position: relative;
}
.forget-password__title:before {
  content: '';
  position: absolute;
  top: 23px;
  right: 0;
  bottom: 0;
  width: 155px;
  left: 0;
  background: linear-gradient(90deg, #31456F 0%, #03C4F7 100%);
}
.forget-password__logo {
  position: absolute;
  top: 20px;
  right: 30px;
}
.forget-password__desc {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2C2C2C;
  margin-bottom: 17px;
  margin-top: 42px;
}
.forget-password .el-radio__inner {
  width: 16px;
  height: 16px;
}
.forget-password .el-radio__inner::after {
  width: 8px;
  height: 8px;
}

.forget-password-page .breadcrumbs-block {
  margin: 30px 0;
}

.forget-password-page .breadcrumbs-block li {
  color: #343f68;
}

.forget-password-page .breadcrumbs-block ul li {
  border-right: 1px solid #343f68;
}

.forget-password-page .breadcrumbs-block ul li:last-child {
  border-right: none;
}

.forget-password-page .breadcrumbs-block a {
  color: #343f68;
}

@media screen and (max-width: 991px) {
  .signin-logo {
    margin-bottom: 43px;
    width: 100%;
    height: 233px;
  }
  .signin-block {
    width: 100%;
    margin: 0 auto;
    padding: 25px 9px 32px 9px;
  }
  .signin-col-form, .signin-col-logo {
    max-width: 100%;
    margin: 0;
  }
  .signin-wrapper {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

}
@media (max-width: 520px) {
  /* .signin-logo {
      width: 237px;
      height: 191px;
  } */
  /* .signin-logo img {
      width: 140px;
  } */
}
@media screen and (max-width: 991px) {
  .signin-logo {
        margin-bottom: 43px;
        width: 100%;
        height: 233px;
    }
}
</style>
